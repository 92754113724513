import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import { financialFormat } from '../helpers/priceAndAmountHelpers'
import { usePrevious } from '../hooks'
import { isTitleNaN, numberWithCommas } from '../utils'

interface AnimatedNumberProps {
  value: number
  color?: string
  className?: string
}
export default function AnimatedNumber({
  value,
  color,
  className,
}: AnimatedNumberProps): React.ReactElement {
  const [animate, setAnimate] = useState(false)
  const previousValue = usePrevious(value)

  useEffect(() => {
    if (value === previousValue) {
      return
    }
    setAnimate(true)

    const timeout = setTimeout(() => {
      setAnimate(false)
    }, 500)

    return (): void => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]) // there should be NO previousValue in dependencies

  return (
    <div className={classNames('number', className)}>
      <div
        className={classNames('current-value', { animate: animate }, color)}
        title={isTitleNaN(numberWithCommas(value))}>
        {financialFormat(value || '0', { decimals: 0, roundBigNumbersFrom: 'B' })}
      </div>
      <div
        className={classNames('previous-value', { animate: animate })}
        title={isTitleNaN(numberWithCommas(previousValue))}>
        {financialFormat(previousValue || '0', { decimals: 0, roundBigNumbersFrom: 'B' })}
      </div>
    </div>
  )
}
