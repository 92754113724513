import React from 'react'

export default function AnimatedInput(): React.ReactElement {
  const randomNumbers = '9357125942'

  const renderNumbers = (): React.ReactElement[] => {
    const renderNumbers = []
    for (let i = 0; i < randomNumbers.length; i++) {
      renderNumbers.push(<span key={i}>{randomNumbers[i]}</span>)
    }
    return renderNumbers
  }

  return <div className="animated-input">{renderNumbers()}</div>
}
