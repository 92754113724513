import classNames from 'classnames'
import { FC } from 'react'

export const BlinkIndicator: FC<{
  className?: string
  state?: 'good' | 'bad' | 'warning'
  stop?: boolean
  speed?: 'fast' | 'slow' | 'normal'
}> = ({ className, state, stop, speed }) => {
  return (
    <span
      className={classNames(
        'blink-indicator',
        className,
        {
          [`blink-indicator--${speed}`]: !!speed,
        },
        {
          [`blink-indicator--${state}`]: !!state,
        },
        {
          'blink-indicator--stop': !!stop,
        }
      )}></span>
  )
}

export default BlinkIndicator
